/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
iframe {
  pointer-events: none;
}
body {
  height: unset;
}
body html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  max-width: 100%;
  border: none;
  margin: 0;
}
.content {
  padding-top: 15px;
  max-width: 1200px;
}
/* #md-react-app {
    // border: 3px dotted blue;
    .password {
        border: 2px dotted red;
    }
    button {
        border: 2px dotted red;
    }
    input[type="submit"] {
        background: red;
    }
    input[type="password"] {
        background: red;
    }
} */
input {
  background-color: hotpink;
}
input #birthday {
  background-color: red;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
button[type="submit"] {
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}
input[type="password"] {
  height: 2rem;
}
.ant-col[ant-form-item-label] {
  border: 2px dotted red;
  margin: 200px;
}
form label {
  color: #909090 !important;
  font-weight: 500;
  padding-left: 1.875px;
}
form div {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
form input {
  margin-bottom: 0 !important;
}
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-primary:active {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary::selection {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:focus {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-link {
  color: #1890ff;
}
.ant-btn-link[disabled] {
  color: rgba(0, 0, 0, 0.3);
}
.ant-btn-link:hover {
  color: #40a9ff;
}
.ant-btn-link:focus {
  color: #40a9ff;
}
.ant-btn-link::selection {
  color: #096dd9;
}
.ant-btn-link:active {
  color: #096dd9;
}
.ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 5px;
}
.ant-input,
.ant-input-number,
.ant-input-number-input,
.ant-select-selection,
.ant-time-picker-input,
.ant-checkbox-inner,
.ant-form-item-label {
  font-size: 0.8rem;
  border-color: #d9d9d9;
  color: #212121;
}
.ant-col.ant-form-item-label {
  line-height: 2;
  padding-left: 0.4rem;
}
.ant-form-item-control {
  line-height: 1.5;
  padding-top: 0.2rem;
  margin-bottom: 0;
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}
.ant-table-header-column {
  font-size: 0.8rem;
}
.ant-table-content {
  font-size: 0.7rem;
}
.table-content-secondary {
  color: #d9d9d9;
}
.table-content-primary {
  font-weight: 500;
}
.ant-modal-content {
  border-radius: 15px;
}
.ant-modal-body {
  overflow: hidden;
}
.ant-modal-header {
  border-radius: 15px 15px 0 0;
}
.ant-modal-title {
  font-size: 1.3rem;
  font-weight: 600;
}
.ant-card {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.1);
}
.header {
  font-size: 0.8em;
}
.primary {
  font-weight: 700;
}
.secondary {
  opacity: 60%;
}
.text__standard {
  font-weight: 400;
  font-size: 0.7rem;
}
.text_large {
  font-size: 0.75rem;
}
.col_coursePrice {
  font-size: 0.75rem;
  text-align: right;
}
.text__bold {
  font-weight: 600;
}
.text__centered {
  text-align: center;
}
.text__colored {
  padding: 2px 10px;
  border-radius: 50px;
  text-overflow: ellipsis;
}
.text__colored span {
  font-size: 0.9em;
  font-weight: 600;
  width: fit-content;
  white-space: nowrap;
}
.bill {
  background-color: #eee;
  border-radius: 5px;
  padding: 0px 7px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: fit-content;
}
.bill span {
  font-size: 0.7rem;
}
.date__due span {
  font-weight: 600;
  font-size: 0.7rem;
}
.bookingNumber {
  font-size: 11px;
}
.coloredBadge {
  color: var(--text-color);
  font-weight: 600;
  padding: 2px 15px;
}
.firstCourseLesson {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.1;
}
.firstCourseLesson .firstCourseLessonDate {
  display: block;
  white-space: nowrap;
}
.firstCourseLesson .firstCourseLessonDateShort {
  display: none;
  white-space: nowrap;
}
.firstCourseLesson .firstCourseLessonTime {
  display: block;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .firstCourseLesson .firstCourseLessonDate {
    display: none;
  }
  .firstCourseLesson .firstCourseLessonDateShort {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .col_coursePrice {
    font-size: 0.8rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .col_coursePrice {
    text-align: center;
  }
}
