.ant-modal-content {
  border-radius: 15px;
  padding: 0;
}
.ant-modal-content .ant-modal-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.ant-modal-footer {
  padding: 16px 16px;
  text-align: center;
}