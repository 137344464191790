/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
iframe {
  pointer-events: none;
}
body {
  height: unset;
}
body html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  max-width: 100%;
  border: none;
  margin: 0;
}
.content {
  padding-top: 15px;
  max-width: 1200px;
}
/* #md-react-app {
    // border: 3px dotted blue;
    .password {
        border: 2px dotted red;
    }
    button {
        border: 2px dotted red;
    }
    input[type="submit"] {
        background: red;
    }
    input[type="password"] {
        background: red;
    }
} */
input {
  background-color: hotpink;
}
input #birthday {
  background-color: red;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
button[type="submit"] {
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}
input[type="password"] {
  height: 2rem;
}
.ant-col[ant-form-item-label] {
  border: 2px dotted red;
  margin: 200px;
}
form label {
  color: #909090 !important;
  font-weight: 500;
  padding-left: 5px;
}
form div {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
form input {
  margin-bottom: 0 !important;
}
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-primary:active {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary::selection {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:focus {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-link {
  color: #1890ff;
}
.ant-btn-link[disabled] {
  color: rgba(0, 0, 0, 0.3);
}
.ant-btn-link:hover {
  color: #40a9ff;
}
.ant-btn-link:focus {
  color: #40a9ff;
}
.ant-btn-link::selection {
  color: #096dd9;
}
.ant-btn-link:active {
  color: #096dd9;
}
.ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 5px;
}
.ant-input,
.ant-input-number,
.ant-input-number-input,
.ant-select-selection,
.ant-time-picker-input,
.ant-checkbox-inner,
.ant-form-item-label {
  font-size: 0.8rem;
  border-color: #d9d9d9;
  color: #212121;
}
.ant-col.ant-form-item-label {
  line-height: 2;
  padding-left: 0.4rem;
}
.ant-form-item-control {
  line-height: 1.5;
  padding-top: 0.2rem;
  margin-bottom: 0;
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}
.ant-table-header-column {
  font-size: 0.8rem;
}
.ant-table-content {
  font-size: 0.7rem;
}
.table-content-secondary {
  color: #d9d9d9;
}
.table-content-primary {
  font-weight: 500;
}
.ant-modal-content {
  border-radius: 15px;
}
.ant-modal-body {
  overflow: hidden;
}
.ant-modal-header {
  border-radius: 15px 15px 0 0;
}
.ant-modal-title {
  font-size: 1.3rem;
  font-weight: 600;
}
.ant-card {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.1);
}
.PanelCard .root {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}
.PanelCard .noBorder {
  border: none;
  padding: 10px;
}
.PanelCard .gap {
  margin-bottom: 10px;
}
.PanelCard .padding {
  padding: 10px;
}
.PanelCard .noPadding {
  padding: 0;
}
.PanelCard .inner {
  --actualColumnCount: var(--column-count);
  --column-gap: 10px;
  color: #545454;
  padding: calc(var(--column-gap) / 2) 0;
  display: grid;
  grid-row-gap: 0;
  grid-column-gap: var(--column-gap);
  grid-template-columns: repeat(var(--actualColumnCount), calc((100% / var(--actualColumnCount)) - var(--column-gap) * (var(--actualColumnCount) - 1) / var(--actualColumnCount)));
  min-height: 0;
}
.PanelCard .title {
  margin: -10px;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px !important;
  border-bottom: 1px solid #d2d2d2;
  font-weight: 600;
}
.PanelCard .title h3 {
  margin: 0 !important;
  line-height: 1;
}
.PanelCard .smallTitle {
  margin: -10px;
  margin-bottom: 10px;
  padding: 7.5px;
  padding-left: 10px !important;
  font-weight: 700;
  font-size: 1rem;
}
