/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
iframe {
  pointer-events: none;
}
body {
  height: unset;
}
body html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  max-width: 100%;
  border: none;
  margin: 0;
}
.content {
  padding-top: 15px;
  max-width: 1200px;
}
/* #md-react-app {
    // border: 3px dotted blue;
    .password {
        border: 2px dotted red;
    }
    button {
        border: 2px dotted red;
    }
    input[type="submit"] {
        background: red;
    }
    input[type="password"] {
        background: red;
    }
} */
input {
  background-color: hotpink;
}
input #birthday {
  background-color: red;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
button[type="submit"] {
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}
input[type="password"] {
  height: 2rem;
}
.ant-col[ant-form-item-label] {
  border: 2px dotted red;
  margin: 200px;
}
form label {
  color: #909090 !important;
  font-weight: 500;
  padding-left: 1.875px;
}
form div {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
form input {
  margin-bottom: 0 !important;
}
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-primary:active {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary::selection {
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:focus {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-link {
  color: #1890ff;
}
.ant-btn-link[disabled] {
  color: rgba(0, 0, 0, 0.3);
}
.ant-btn-link:hover {
  color: #40a9ff;
}
.ant-btn-link:focus {
  color: #40a9ff;
}
.ant-btn-link::selection {
  color: #096dd9;
}
.ant-btn-link:active {
  color: #096dd9;
}
.ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 5px;
}
.ant-input,
.ant-input-number,
.ant-input-number-input,
.ant-select-selection,
.ant-time-picker-input,
.ant-checkbox-inner,
.ant-form-item-label {
  font-size: 0.8rem;
  border-color: #d9d9d9;
  color: #212121;
}
.ant-col.ant-form-item-label {
  line-height: 2;
  padding-left: 0.4rem;
}
.ant-form-item-control {
  line-height: 1.5;
  padding-top: 0.2rem;
  margin-bottom: 0;
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}
.ant-table-header-column {
  font-size: 0.8rem;
}
.ant-table-content {
  font-size: 0.7rem;
}
.table-content-secondary {
  color: #d9d9d9;
}
.table-content-primary {
  font-weight: 500;
}
.ant-modal-content {
  border-radius: 15px;
}
.ant-modal-body {
  overflow: hidden;
}
.ant-modal-header {
  border-radius: 15px 15px 0 0;
}
.ant-modal-title {
  font-size: 1.3rem;
  font-weight: 600;
}
.ant-card {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.1);
}
.root {
  background-color: transparent;
}
.leftSide {
  background-color: #f5f5f5;
}
.header {
  display: flex;
  justify-content: space-between;
}
.underLine {
  border-bottom: 1px solid lightgrey;
}
.content {
  padding-top: 5px;
}
h1 {
  font-weight: 700;
  color: #979ea5;
}
.extraBig {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1;
  padding-top: 7.5px;
  padding-bottom: 15px;
}
.primary {
  color: #545454;
}
.secondary {
  color: #929292;
  margin-left: 8px;
}
.subline {
  color: #929292;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 15px;
}
.ant-descriptions {
  border: 1px solid #f5f5f5;
  border-color: rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  margin: 0px -15px 15px -15px;
  padding: 0px 15px;
}
.ant-descriptions-title {
  color: #979ea5;
}
.ant-descriptions-header {
  margin: 30px 0px;
}
.ant-descriptions {
  padding-bottom: 30px;
}
.ant-descriptions-row td {
  padding-bottom: 0px;
}
.ant-descriptions-item-label {
  min-width: 33%;
}
.monthlyPaymentEntry {
  font-size: 0.9rem;
  font-weight: 600;
  color: #545454;
}
.monthlyPaymentEntry .main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b8b8b8;
  margin-top: 7.5px;
}
.monthlyPaymentEntry .sub {
  width: 100%;
  font-size: 10px;
  text-align: right;
  color: #b8b8b8;
}
.totalPrice {
  display: flex;
  justify-content: space-between;
  color: #545454;
  border-bottom: 1px solid #929292;
  font-weight: 700;
  font-size: 20px;
}
.totalPriceSub {
  margin-bottom: 5px;
  margin-left: auto;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  color: #929292;
}
.totalSpecialPrice {
  font-weight: 700;
  color: #545454;
  margin-bottom: 10px;
}
.centerdButton {
  align-self: center;
}
.titlePrimary {
  font-weight: 700;
}
.titleSecondary {
  color: #545454;
  display: flex;
  justify-content: space-between;
}
